import React from 'react';
import { Text, TextVariant } from '@naf/text';
import { CardGridRow } from '../../../pages/SectionPage/Styles';
import { ArticleCard } from '../../../pages/SectionPage/components/ArticleCard';
import { RelatedArticleType } from '../../../../../types/articleType';

export interface TwoArticleBlockType {
  article1: RelatedArticleType;
  article2: RelatedArticleType;
}

interface TwoArticleBlockProps {
  node: TwoArticleBlockType;
}

export const TwoArticleBlock = ({ node: { article1, article2 } }: TwoArticleBlockProps) => (
  <div>
    <Text tag="h2" variant={TextVariant.Header2}>
      Aktuelle saker
    </Text>
    <CardGridRow>
      {article1 && <ArticleCard article={article1} />}
      {article2 && <ArticleCard article={article2} />}
    </CardGridRow>
  </div>
);
